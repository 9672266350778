<template>
    <div class="container flex flex-col">
        <div class="bg-white-pure list-wrapper">
            <div class="mb-2">
                <BackLink :backRoute="reportsListRoute" :linkText="goBackText" />
            </div>

            <div class="font-weight-bold mb-4 text-center text-h3 black--text">Summary Report</div>
            <div class="mb-4 text-sm text-center black--text">QLD - WEST MORETON | DD/MM/YYYY - DD/MM/YYYY</div>

            <div
                v-for="(table, itemIndex) in summaries"
                :key="`summary-${itemIndex}`"
            >
                <h6 class="font-semibold ml-8 mt-6">{{ table.title }}</h6>
                <v-data-table
                    v-for="(data, tableIndex) in table.tableData"
                    :key="`table-${itemIndex}-${tableIndex}`"
                    dense
                    class="ml-12"
                    :headers="data.headers"
                    :items="data.data"
                    hide-default-footer
                >
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import BackLink from '@/components/shared/BackLink'
import { ref } from '@vue/composition-api'

export default {
    name: 'Summary',
    components: { BackLink },
    setup() {
        const goBackText = ref('Back to Reports')
        const reportsListRoute = ref({
            name: 'reports'
        })
        const summaries = ref([
            {
                title: '',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'value', align: 'right', sortable: false }
                        ],
                        data: [
                            { subject: 'Referrals', value: 0 },
                            { subject: 'Brief Assessment (Total)', value: 0 },
                            { subject: 'Brief Assessment (Pre)', value: 0 },
                            { subject: 'Brief Assessment (Post)', value: 0 },
                            { subject: 'Assessments', value: 0 },
                            { subject: 'Clients (total)', value: 0 },
                        ]
                    }
                ]
            },
            {
                title: 'Gender',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Male', value: 0, number: 'Number' },
                            { subject: 'Female', value: 0, number: 'Number' },
                        ]
                    },
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Active Clients at end date', value: 0 },
                            { subject: 'Age Range', value: 0 },
                            { subject: 'Average Age', value: 0 },
                            { subject: 'Average Wait Time', value: 0 },
                            { subject: 'Shortest Wait Time', value: 0 },
                            { subject: 'Longest Wait Time', value: 0 },
                            { subject: 'Avg days referral to 1st appt.', value: 0 }
                        ]
                    }
                ]
            },
            {
                title: 'Ethnicity Language - Birth Country',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Country', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Ethnicity Language - Preferred Language',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Language', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Ethnicity Language - Cultural Group',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Cultural Group', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'ATSI Status',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'ATSI status', number: 'Number', value: '0%' },
                            { subject: 'ATSI Client', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Completion',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Total Program Complete', number: 'Number', value: '0.00%' },
                            { subject: 'Self Discharge Total', number: 'Number', value: '0.00%' },
                            { subject: 'Disciplinary Discharge – Total', number: 'Number', value: '0.00%' }
                        ]
                    },
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Occupancy Rate', value: '0.00%' },
                            { subject: 'Retention Rate', value: '0.00%' }
                        ]
                    }
                ]
            },
            {
                title: 'Referral Source',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Source', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'JJ clients',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Non JJ client', number: 'Number', value: '0%' },
                            { subject: 'JJ client', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Detention and Arrests',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Have been arrested', number: 'Number', value: '0%' },
                            { subject: 'Have been incarcerated', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Primary Drugs of Concern',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Drug', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Secondary Drug of Concern',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Drug', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Mental Health',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Drug', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Level of Schooling',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Highest completed level of Schooling', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Homelessness',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Homelessness', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Attempted Suicide',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Ever Attempted Suicide', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Trauma',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Combat/war zone', number: 'Number', value: '0%' },
                            { subject: 'Life threatening illness', number: 'Number', value: '0%' },
                            { subject: 'Natural disaster', number: 'Number', value: '0%' },
                            { subject: 'Other', number: 'Number', value: '0%' },
                            { subject: 'Physical assault by someone you know', number: 'Number', value: '0%' },
                            { subject: 'Physical assault by stranger', number: 'Number', value: '0%' },
                            { subject: 'Serious Accident', number: 'Number', value: '0%' },
                            { subject: 'Severe/ongoing emotional/verbal abuse', number: 'Number', value: '0%' },
                            { subject: 'Sexual assault by someone you know', number: 'Number', value: '0%' },
                            { subject: 'Sexual Assault stranger', number: 'Number', value: '0%' },
                            { subject: 'Torture	', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            },
            {
                title: 'Referral to Another Service',
                tableData: [
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Referral to Another Service', number: 'Number', value: '0%' }
                        ]
                    },
                    {
                        headers: [
                            { text: '', value: 'subject', sortable: false },
                            { text: '', value: 'number', width: '80px', align: 'end', sortable: false },
                            { text: '', value: 'value', width: '80px', align: 'end', sortable: false }
                        ],
                        data: [
                            { subject: 'Counselling sessions', number: 'Number', value: '0%' },
                            { subject: 'Treatment Events', number: 'Number', value: '0%' },
                            { subject: 'Action Plan commenced', number: 'Number', value: '0%' },
                            { subject: 'Groups / Workshops', number: 'Number', value: '0%' },
                            { subject: 'Groups / Workshops Attendees', number: 'Number', value: '0%' }
                        ]
                    }
                ]
            }
        ])
        return {
            goBackText,
            reportsListRoute,
            summaries
        }
    }
}
</script>